import { getClientAds, sendImpression } from "api-v2/kevel";
import { Decision } from "api-v2/kevel/types";
import { forwardRef, useEffect, useRef, useState } from "react";
import { InView, useInView } from "react-intersection-observer";
import Markdown from "react-markdown";
import { cn } from "../../utils";
import { Icon } from "./icon/icon";
import { buttonVariants } from "./button";
import { gtmEvent, gtmPurchaseEvent } from "utils/gtm";
import { useVisibility } from "../../hooks/use-visibility";

type BapVariants = "billboard" | "leaderboard" | "compact" | "fluid";
type DecisionData = {
  ctTitle: string;
  ctDisplayImageUrl: string;
  ctSubtitle?: string;
  ctBackgroundColor?: string;
  ctButtonColor?: string;
  ctButtonTextColor?: string;
  ctTitleColor?: string;
  ctCtaText: string;
  ctTarget: string;
  ctCpcValue: string;
};
export interface BapAdProps {
  variant: BapVariants;
  mobile?: boolean;
  desktop?: boolean;
  className?: string;
  properties?: Record<string, string>;
  hideImage?: boolean;
  hideButton?: boolean;
  imageClassName?: string;
  wrapperClassName?: string;
}

const getStyle = (variant: string) => {
  switch (variant) {
    case "billboard":
      return { width: "100%", height: "250px" };
    case "leaderboard":
      return { width: "100%", height: "90px" };
    case "compact":
      return { width: "100%", height: "54px" };
    case "fluid":
      return { width: "auto", height: "auto" };
  }
};

const BillboardAd = forwardRef(
  (
    {
      ad,
      onClickout,
    }: {
      ad: Decision<DecisionData>;
      onClickout: () => void;
    },
    ref
  ) => {
    const data = ad.contents[0].data as DecisionData;

    return (
      <div
        ref={ref}
        className="relative w-[calc(100%-2rem)] mx-auto h-[250px] rounded lg:w-full"
        style={{
          backgroundColor: data.ctBackgroundColor || "#EEEEEE",
          color: data.ctTitleColor || "#262C48",
        }}
      >
        <div className="relative w-full flex h-full mx-auto p-6 pr-0 lg:p-4 lg:gap-12 lg:max-w-[80%]">
          <div className="absolute top-0 left-4 bg-tertiary text-tertiary-foreground rounded-b h-8 px-2.5 flex items-center gap-1.5 w-fit text-xs">
            <Icon icon="partnerbijdrage" size={12} />
            Partnerbijdrage
          </div>

          <div className="w-3/5 flex flex-col justify-center gap-4 pt-8 lg:gap-6">
            <div className="flex flex-col gap-2">
              <Markdown className="text-lg" components={{ p: "span" }}>
                {data.ctTitle}
              </Markdown>
              {data.ctSubtitle && (
                <p className="my-0 hidden md:block">{data.ctSubtitle}</p>
              )}
            </div>

            <a
              href={ad.clickUrl}
              target={data.ctTarget}
              onClick={onClickout}
              className={cn(
                buttonVariants(),
                "w-fit font-normal hover:opacity-90"
              )}
              style={{
                backgroundColor: data.ctButtonColor || "#262C48",
                color: data.ctButtonTextColor || "#FFFFFF",
              }}
            >
              {data.ctCtaText}
            </a>
          </div>
          <div className="w-2/5 flex-1 relative h-full">
            <img
              src={data.ctDisplayImageUrl}
              className="absolute top-0 left-0 w-full h-full object-cover object-left lg:object-center lg:object-contain"
            />
          </div>
        </div>

        <a
          href={ad.clickUrl}
          target={data.ctTarget}
          onClick={onClickout}
          className="absolute top-0 left-0 w-full h-full"
        >
          <span className="sr-only">Bekijk partnerbijdrage</span>
        </a>
      </div>
    );
  }
);

BillboardAd.displayName = "BillboardAd";

const CompactAd = forwardRef(
  (
    {
      ad,
      onClickout,
    }: {
      ad: Decision<DecisionData>;
      onClickout: () => void;
    },
    ref
  ) => {
    const data = ad.contents[0].data as DecisionData;

    return (
      <div
        ref={ref}
        className="relative h-[54px] pl-4 pr-2 flex items-center rounded"
        style={{
          backgroundColor: data.ctBackgroundColor || "#EEEEEE",
          color: data.ctTitleColor || "#262C48",
        }}
      >
        <Markdown className="text-sm flex-1" components={{ p: "span" }}>
          {data.ctTitle}
        </Markdown>

        <a
          href={ad.clickUrl}
          target={data.ctTarget}
          onClick={onClickout}
          className={cn(
            buttonVariants(),
            "w-fit font-normal hover:opacity-90 h-[25px] text-sm"
          )}
          style={{
            backgroundColor: data.ctButtonColor || "#262C48",
            color: data.ctButtonTextColor || "#FFFFFF",
          }}
        >
          {data.ctCtaText}
        </a>

        <a
          href={ad.clickUrl}
          target={data.ctTarget}
          onClick={onClickout}
          className="absolute top-0 left-0 w-full h-full"
        >
          <span className="sr-only">Bekijk partnerbijdrage</span>
        </a>
      </div>
    );
  }
);
CompactAd.displayName = "CompactAd";

const FluidAd = forwardRef(
  (
    {
      ad,
      onClickout,
      hideImage,
      hideButton,
      imageClassName,
      wrapperClassName,
    }: {
      ad: Decision<DecisionData>;
      onClickout: () => void;
      hideImage?: boolean;
      hideButton?: boolean;
      imageClassName?: string;
      wrapperClassName?: string;
    },
    ref
  ) => {
    const data = ad.contents[0].data as DecisionData;

    return (
      <div
        ref={ref}
        className={cn(
          "relative p-4 flex flex-wrap gap-3 items-center rounded",
          wrapperClassName
        )}
        style={{
          backgroundColor: data.ctBackgroundColor || "#EEEEEE",
          color: data.ctTitleColor || "#262C48",
        }}
      >
        {!hideImage && (
          <img
            src={data.ctDisplayImageUrl}
            className={cn("absolute right-2 bottom-2 h-1/2", imageClassName)}
          />
        )}

        <Markdown className="text-base w-full z-[1]" components={{ p: "span" }}>
          {data.ctTitle}
        </Markdown>

        {!hideButton && (
          <a
            href={ad.clickUrl}
            target={data.ctTarget}
            onClick={onClickout}
            className={cn(
              buttonVariants(),
              "w-fit font-normal hover:opacity-90"
            )}
            style={{
              backgroundColor: data.ctButtonColor || "#262C48",
              color: data.ctButtonTextColor || "#FFFFFF",
            }}
          >
            {data.ctCtaText}
          </a>
        )}

        <a
          href={ad.clickUrl}
          target={data.ctTarget}
          onClick={onClickout}
          className="absolute top-0 left-0 w-full h-full"
        >
          <span className="sr-only">Bekijk partnerbijdrage</span>
        </a>
      </div>
    );
  }
);
FluidAd.displayName = "FluidAd";

const LeaderboardAd = forwardRef(
  (
    {
      ad,
      onClickout,
      hideImage,
      hideButton,
    }: {
      ad: Decision<DecisionData>;
      onClickout: () => void;
      hideImage?: boolean;
      hideButton?: boolean;
    },
    ref
  ) => {
    const data = ad.contents[0].data as DecisionData;

    return (
      <div
        ref={ref}
        className="relative px-3 py-2 rounded md:px-5 md:py-4"
        style={{
          backgroundColor: data.ctBackgroundColor || "#EEEEEE",
          color: data.ctTitleColor || "#262C48",
        }}
      >
        <div className="grid grid-cols-5 items-center max-w-4xl mx-auto">
          <div className="flex flex-col col-span-3">
            <Markdown
              className="text-base lg:text-lg"
              components={{ p: "span" }}
            >
              {data.ctTitle}
            </Markdown>
          </div>

          {!hideImage && (
            <div
              className={cn("relative h-[64px]", { "col-span-2": hideButton })}
            >
              <img
                src={data.ctDisplayImageUrl}
                className="absolute top-0 left-0 w-full h-full object-center object-contain"
              />
            </div>
          )}

          {!hideButton && (
            <div
              className={cn("flex justify-end", { "col-span-2": hideImage })}
            >
              <a
                href={ad.clickUrl}
                target={data.ctTarget}
                onClick={onClickout}
                className={cn(
                  buttonVariants(),
                  "w-fit font-normal hover:opacity-90"
                )}
                style={{
                  backgroundColor: data.ctButtonColor || "#262C48",
                  color: data.ctButtonTextColor || "#FFFFFF",
                }}
              >
                {data.ctCtaText}
              </a>
            </div>
          )}
        </div>

        <a
          href={ad.clickUrl}
          target={data.ctTarget}
          onClick={onClickout}
          className="absolute top-0 left-0 w-full h-full"
        >
          <span className="sr-only">Bekijk partnerbijdrage</span>
        </a>
      </div>
    );
  }
);

LeaderboardAd.displayName = "LeaderboardAd";

const BapAd = ({
  variant,
  properties,
  desktop,
  mobile,
  className,
  ...rest
}: BapAdProps) => {
  const { ref, inView } = useInView({ threshold: 0.5 });

  const [visibleTime, setVisibleTime] = useState(0);

  const [ad, setAd] = useState<Decision<DecisionData> | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [viewEventFired, setViewEventFired] = useState(false);
  const tabActive = useVisibility();
  const refreshInterval = useRef<NodeJS.Timeout>();

  const onFetchAd = async (inView: boolean) => {
    if (!inView) return;

    const { data: ads } = await getClientAds({
      placements: [
        {
          divName: "bap",
          adTypes: [71],
          count: 1,
          properties,
        },
      ],
    });

    const currentAd = ads.decisions.bap?.[0];

    if (currentAd) {
      setAd(currentAd);

      gtmEvent(`kevel_bap_ad_delivered`, currentAd);
      await sendImpression(currentAd.impressionUrl);

      console.log(currentAd.adId, "Sending delivered event");
    }

    setIsLoaded(true);
  };

  const sendViewEvent = async () => {
    if (!ad) return;

    gtmEvent("kevel_bap_ad_inview", ad);

    const viewEvent = ad.events.find(({ id }) => id === 40);
    if (!viewEvent) return;

    await sendImpression(viewEvent.url);
    console.log(ad.adId, "Sending view event");
  };

  useEffect(() => {
    if (!ad) return;

    if (inView && tabActive) {
      if (visibleTime === 1 && !viewEventFired) {
        void sendViewEvent();
        setViewEventFired(true);
      }

      if (visibleTime >= 30) {
        clearInterval(refreshInterval.current);
      } else {
        refreshInterval.current = setInterval(() => {
          setVisibleTime((prev) => prev + 1);
        }, 1000);
      }
    } else {
      return clearTimeout(refreshInterval.current);
    }

    return () => {
      return clearTimeout(refreshInterval.current);
    };
  }, [ad, inView, tabActive, visibleTime, viewEventFired]);

  const handleClickout = () => {
    if (!ad) return;
    const data = ad.contents[0].data as DecisionData;

    console.log("ad clicked", ad);
    gtmEvent("kevel_bap_ad_clicked", ad);
    gtmEvent("bap_purchase", {
      value: data.ctCpcValue,
      ...data,
    });

    gtmPurchaseEvent({
      value: 0,
      webshop: "",
      position: "bap",
      additionalValue: Number(data.ctCpcValue),
      title: data.ctTitle,
    });
  };

  return (
    <InView
      onChange={onFetchAd}
      triggerOnce
      className={cn(
        { "hidden lg:block": desktop, "block lg:hidden": mobile },
        className
      )}
      style={isLoaded ? { width: "auto", height: "auto" } : getStyle(variant)}
    >
      {ad && variant === "billboard" && (
        <BillboardAd ref={ref} ad={ad} onClickout={handleClickout} {...rest} />
      )}

      {ad && variant === "leaderboard" && (
        <LeaderboardAd
          ref={ref}
          ad={ad}
          onClickout={handleClickout}
          {...rest}
        />
      )}

      {ad && variant === "compact" && (
        <CompactAd ref={ref} ad={ad} onClickout={handleClickout} {...rest} />
      )}

      {ad && variant === "fluid" && (
        <FluidAd ref={ref} ad={ad} onClickout={handleClickout} {...rest} />
      )}
    </InView>
  );
};

export { BapAd };
